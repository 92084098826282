.App {
  font-family: serif; /* Change to a generic serif font */
  /* text-align: center; */
}

.story {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  font-size: 18px; /* Set story text size */
  /* font-size: clamp(2rem, 5vw + 1rem, 8rem); */
  /* font-family: 'Cooper Black', 'Caprasimo', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
}

.story-text {
  font-size: 18px; /* Set story text size */
    font-family: 'Cooper Black', 'Caprasimo', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


.options table {
  width: 100%;
  border-collapse: collapse;
}

.options td {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  text-align: left; /* Default alignment for table cells */
  font-size: 18px; /* Set option text size */;
}

.option-button {
  width: 150px; /* Adjust width as needed */
}

.option-button button {
  width: 100%; /* Make button fill the cell width */
}
